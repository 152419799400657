.dimmer {
	position: fixed;
	top: 0;
	left: 0;
	width: 100vw;
	height: 100vh;
	z-index: 1000;

	display: none;
	justify-content: center;
	align-items: center;

	background-color: rgba(0, 0, 0, .3);
	backdrop-filter: blur(2px);

	&:nth-last-child(1 of .dimmer){
		display: flex;
	}
}

.dialog {
	border-radius: 4px;
	background-color: white;
	overflow: hidden;
}

/* https://robbowen.digital/wrote-about/locking-scroll-with-has */
body:has(.dialog) {
	max-width: 100vw;
	max-height: 100vh;
	overflow: hidden;
}
