.header {
	position: relative;
	display: flex;
	justify-content: center;
	padding: 80px;

	color: white;
	background: #1F1F20;
}

.banner {
	width: 586px;
	height: 420px;
	margin-right: 5vw;
}

.title {
	font-size: 3em;
	margin: 0;
}

.subTitle {
	margin-bottom: 50px;
}

.bigButton {
	display: flex;
	align-items: center;
	justify-content: center;

	width: 200px;
	height: 46px;
	margin: 20px 0;
	font-size: 20px;
}

.github {
	font-size: 28px;
	margin-right: 8px;
}

.unsplash {
	position: absolute;
	right: 10px;
	bottom: 10px;
	font-size: 14px;
}

.link {
	margin: 0 5px;

	&, &:visited {
		color: #ff3ba3;
	}
}

.section {
	padding: 50px;
}

.links {
	composes: section;
	display: flex;
	justify-content: center;
}

.card {
	display: flex;
	flex-direction: column;
	gap: 10px;
	margin: 0 75px;
}
