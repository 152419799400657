
.container {
	--active-color: #2183ff;
	--active-light: #a6ccff;
	--thumb-size: 18px;

	position: relative;

	&::before {
		content: "";
		display: block;

		position: absolute;
		left: 0;
		top: calc(50% - 1px);
		width: 100%;
		height: 2px;

		background:
				linear-gradient(var(--active-color), var(--active-color))
				0 / var(--value-percent) 100% no-repeat #ddd;
	}

	&.disabled {
		--active-color: #888;
		--active-light: #888;
	}
}

.thumbRegion {
	display: flex;
	align-items: center;
	position: absolute;
	top: 0;
	left: 0;
	bottom: 0;
	right: var(--thumb-size);
	pointer-events: none;
}

.thumb {
	width: var(--thumb-size);
	height: var(--thumb-size);
	padding: 3px;
	margin-left: var(--value-percent);

	border-radius: 50%;
	border: 2px solid var(--active-color);
	background: var(--active-color);
	box-sizing: border-box;

	&::before {
		content: "";
		display: block;
		width: 8px;
		height: 8px;
		border-radius: 50%;
		background: var(--active-color);
	}
}

.input {
	display: block;
	width: 100%;
	margin: 0;
	opacity: 0;
}

.input:active + .thumbRegion > .thumb {
	background: var(--active-light);
}
