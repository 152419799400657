.container {
	position: relative;
}

.tabPanel {
	composes: controls from global;

	position: absolute;
	top: 0;
	right: 0;
	border-bottom-left-radius: 4px;
}

.option {
	display: flex;
	align-items: center;
	padding: 10px;
}

.icon {
	font-size: 24px;
	margin-right: auto;
}

.main {
	--tile: #eee;

	display: flex;
	justify-content: center;
	align-items: center;

	width: 100%;
	height: 100%;
	overflow: hidden;

	background-image:
			linear-gradient(45deg, var(--tile) 25%, transparent 0),
			linear-gradient(45deg, transparent 75%, var(--tile) 0),
			linear-gradient(45deg, var(--tile) 25%, transparent 0),
			linear-gradient(45deg, transparent 75%, var(--tile) 0);
	background-size: 20px 20px;
	background-position: 0 0, 10px 10px, 10px 10px, 0 0;
}

.wrapper {
	position: relative;
	flex-shrink: 0;

	/* Disable image smoothing when scaling, see https://stackoverflow.com/a/14068216 */
	image-rendering: crisp-edges;          		/* Firefox */
	image-rendering: -o-crisp-edges;            /* Opera */
	image-rendering: -webkit-optimize-contrast; /* Chrome (and eventually Safari) */
	image-rendering: pixelated; 				/* Chrome */
	image-rendering: optimize-contrast;			/* CSS3 Proposed */

	filter: brightness(var(--brightness));
	transform: translate(var(--x), var(--y)) scale(var(--scale));
	will-change: filter, transform;
}

.canvas {
	--offset: calc((var(--split) - 50vw  - var(--x)) / var(--scale));

	position: absolute;
	clip-path: inset(0 calc(50% - var(--offset)) 0 0);

	& + .canvas {
		clip-path: inset(0 0 0 calc(50% + var(--offset)));
	}
}

.controls {
	position: absolute;
	left: 50%;
	bottom: 8px;
	transform: translateX(-50%);

	display: flex;
}

.separator {
	position: absolute;
	z-index: 3;
	top: 0;
	bottom: 0;
	width: 6px;
	left: calc(var(--split) - 3px);

	cursor: ew-resize;
	background: rgba(0, 0, 0, 0.6);
}
