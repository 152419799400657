.header {
	display: flex;
	align-items: center;
	margin-bottom: 10px;
}

.input {
	composes: no-spinner from global;

	margin-left: auto;
	width: 5em;
	padding: 2px 4px;
	font-size: inherit;
	border: solid 1px transparent;

	text-align: right;
	color: inherit;
	background: none;

	&:focus {
		border: solid 1px #aaa;
		outline: none;
	}

	&:invalid {
		border: solid 1px #e62e2e;
		box-shadow: 0 0 3px 1px red;
	}
}
