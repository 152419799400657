.fileDropBox {
	display: flex;
	flex-direction: column;
	justify-content: center;
	align-items: center;

	position: relative;
	cursor: pointer;
	border: dashed 5px #ccc;
	transition: .2s;

	&.dragging,
	&:focus-within,
	&:hover {
		outline: none;
		border-color: #76b3ff;

		& .text {
			color: #4d89ff;
		}

		& .icon {
			color: #da76ff;
		}
	}
}

.text {
	font-size: 3em;
	color: #ccc;
	transition: .2s;
	vertical-align: middle;
}

.icon {
	composes: text;

	display: inline-block;
	font-size: 5em;
	margin-right: .1em;
}

.fileInput {
	position: absolute;
	opacity: 0;
	z-index: -1;
}

.dirInput {
	margin-top: 10px;
}
