.dialog {
	display: flex;
	flex-direction: column;
	width: 690px;
	height: 560px;
}

.header {
	display: flex;
}

.title {
	margin: 20px 20px 0 20px;
	align-self: center;
}

.tab {
	position: relative;
	flex: 1;
	padding: 10px;
	border: dotted 2px transparent;
	text-align: left;

	&[aria-selected="false"] {
		transition: background-color .1s;
		color: white;
		background: #777;
		cursor: pointer;

		&:hover, &:focus-visible {
			background: #999;
		}
	}

	&:focus-visible {
		border-color: #0061e0;
	}
}
