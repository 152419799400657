@import "./highcharts.css";

* {
	box-sizing: border-box;
}

html {
	font-family: Arial, sans-serif;
	color: #333;
}

body {
	margin: 0;
}

fieldset {
	padding: 0;
	margin: 0;
	border: none;
}

button {
	font-family: Arial, sans-serif;
	font-size: initial;
	border: none;
	border-radius: 0;
	background: none;

	&:enabled {
		cursor: pointer;
	}

	&:focus {
		outline: none;
	}
}

a {
	text-decoration: none;

	&:hover, &:focus-visible {
		text-decoration: underline;
	}
}

/* https://stackoverflow.com/a/4298216 */
.no-spinner {
	-moz-appearance: textfield;

	&::-webkit-outer-spin-button,
	&::-webkit-inner-spin-button {
		margin: 0;
		-webkit-appearance: none;
	}
}

/* Ignore corners overflow */
.controls {
	color: white;
	background: rgba(0, 0, 0, 0.8);
	backdrop-filter: blur(2px);
}

/* Convenience class to hide an element */
.hidden {
	display: none !important;
}

.dialog-content {
	padding: 20px;
	flex: 1;
	overflow: auto;
}

.dialog-actions {
	display: flex;
	justify-content: flex-end;
	align-items: center;
	padding: 0 20px 20px;

	& > :not(:first-child) {
		margin-left: 10px;
	}
}
