.container {
	--border-color: #d7d7d7;
	--border-active-color: rgb(0, 128, 255, .8);
	--button-bg: var(--border-color);
	--border-radius: 4px;

	display: inline-flex;
	border-radius: var(--border-radius);

	vertical-align: middle;

	color: black;
	background: var(--background-input);

	&:focus-within {
		box-shadow: 0 0 0 3px var(--border-active-color);
	}
}

.input {
	composes: no-spinner from global;

	width: var(--input-width, 60px);
	min-width: 0;
	padding: 5px;

	border: solid 1px var(--border-color);
	border-radius: 0;
	font-size: inherit;
	font-weight: inherit;

	text-align: center;
	color: currentColor;
	background: none;

	&:focus {
		outline: none;
	}

	&:invalid {
		--border-color: red;
	}
}

.button {
	font-size: var(--size, 24px);
	padding: 0 3px;
	line-height: 1rem;

	color: currentColor;
	background-color: var(--button-bg);

	border: solid 1px var(--border-color);
	border-right-width: 0;

	&:first-child {
		border-radius: var(--border-radius) 0 0 var(--border-radius);
	}

	&:last-child {
		border-radius: 0 var(--border-radius) var(--border-radius) 0;
	}

	&:disabled {
		cursor: default;
		background-color: rgb(227, 227, 227);
	}
}

.input + .button {
	border-left-width: 0;
	border-right-width: 1px;
}
