.drop {
	width: 600px;
	height: 320px;
}

.demoLabel {
	display: block;
	margin: 20px 0 6px 0;
}

.demo {
	display: grid;
	width: 600px;
	grid-gap: 10px;
	grid-template-columns: repeat(3, 1fr);
}

.error {
	margin-right: auto;
	color: #ff1e1e;
}

/* Styles of DemoButton */
.card {
	--icon-size: 48px;
	--padding: 8px;
	--loading-size: 40px;

	display: flex;
	align-items: center;
	position: relative;

	padding: var(--padding);
	border-radius: 4px;
	border: 1px solid #ccc;

	&:hover, &:focus-visible {
		background-color: #f0f0f0;
	}
}

.demoIcon {
	display: block;
	width: var(--icon-size);
	height: var(--icon-size);
}

.demoDescription {
	flex: 1;
	margin-left: 10px;
	line-height: 1.3;
	text-align: start;
}

.loading {
	position: absolute;
	top: var(--padding);
	left: var(--padding);
	width: var(--icon-size);
	height: var(--icon-size);

	background: rgba(0, 0, 0, .6);

	&::before, &::after {
		content: "";
		display: block;
		position: absolute;

		border: 4px solid #fff;
		opacity: 1;
		border-radius: 50%;
		animation: lds-ripple 1s cubic-bezier(0, 0.2, 0.8, 1) infinite;
	}

	&::after {
		animation-delay: -0.5s;
	}
}

@keyframes lds-ripple {
	0% {
		top: calc(var(--loading-size) / 2);
		left: calc(var(--loading-size) / 2);
		width: 0;
		height: 0;
		opacity: 1;
	}
	100% {
		top: 0;
		left: 0;
		width: var(--loading-size);
		height: var(--loading-size);
		opacity: 0;
	}
}
