.container {
	composes: controls from global;

	position: relative;
}

.chart {
	width: 600px;
	height: 380px;
}

.lock {
	composes: dark from global;

	position: absolute;
	top: 17px;
	left: 15px;
	z-index: 2;

	padding: 5px;
	font-size: 24px;
	border-radius: 4px;

	color: #ddd;
	background-color: #505053;
}
