.form {
	display: grid;
	grid-template-columns: auto 1fr;
	align-items: start;
	row-gap: 24px;
	column-gap: 8px;
}

.default {
	padding: 6px;
	font-size: 24px;
	border-radius: 4px;
}

.constant {
	composes: default;
	color: #2183ff;
}

.variable {
	composes: default;
	color: #00b900;
}

.empty {
	color: #555;
	grid-column: 1/-1;
	align-self: center;
	justify-self: center;
	font-size: 1.125rem;
}
