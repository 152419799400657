.container {
	--thumb-color: white;
	--width: 55px;
	--height: 24px;
	--padding: 4px;

	display: inline-block;
	position: relative;

	padding: var(--padding);
	width: var(--width);
	height: var(--height);

	border-radius: calc(var(--height) / 2);
	cursor: pointer;
	background: #999;

	&:focus-within {
		outline: dotted 2px;
	}

	&::before {
		--thumbSize: calc(var(--height) - var(--padding) * 2);

		content: "";
		display: block;

		width: var(--thumbSize);
		height: var(--thumbSize);

		border-radius: 50%;
		background: var(--thumb-color);
		transition: margin-left .2s;
	}
}

.checked {
	background: #0776df;

	&::before {
		margin-left: calc(var(--width) - var(--height));
	}
}

.disabled {
	--thumb-color: #eee;

	cursor: default;
	background: #bbb;
}

.input {
	position: absolute;
	top: 0;
	opacity: 0;
	cursor: inherit;
}
