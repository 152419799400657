.container {
	position: absolute;
	padding: 6px;

	font-size: .875em;
	font-family: SFMono-Regular, Menlo, Monaco, Consolas, Liberation Mono, Courier New, monospace;
	white-space: nowrap;
	transform: translateY(-100%);

	/*
	 * Allow moving when the mouse moves to the box.
	 * this also prevents user select, which needs pin the box.
	 */
	pointer-events: none;

	background: #222;
	color: white;
	border: solid 1px white;
}

.color {
	display: flex;
	justify-content: space-between;
	align-items: center;

	margin: 2px;

	&::before {
		content: "";
		display: inline-block;
		width: .875em;
		height: .875em;
		margin-right: 8px;
		background-color: var(--color);
	}
}
