.container {
	composes: controls from global;

	position: absolute;
	right: 0;
	bottom: 0;
	width: 320px;
	max-height: 400px;

	overflow-y: auto;
	border-top-left-radius: 4px;
}

.field {
	padding: 10px;
	border-left: solid 5px transparent;

	&.active {
		border-left-color: #0776df;
	}
}

.radioButtonGroup {
	display: grid;
	border: solid 1px #ccc;
	border-top: 0;
	border-left: 0;
}
