.dialog {
	display: flex;
	width: 920px;
	height: 500px;
}

.list {
	list-style: none;
	flex: 1;
	margin: 0;
	padding: 0;
	overflow: hidden scroll;
}

.placeholder {
	composes: list;
	font-size: 24px;
	color: gray;
	text-align: center;
}

.listitem {
	display: grid;
	grid-template-areas: "img header" "img name" "img attrs";
	grid-template-columns: 160px 1fr;
	grid-template-rows: auto auto 1fr;
	gap: 10px;
	padding: 20px;

	&:hover,
	&.dragging {
		background: #f2f2f2;
	}
}

.canvas {
	grid-area: img;
	justify-self: center;
	align-self: center;

	display: block;
	max-width: 160px;
	max-height: 120px;
}

.attrLine {
	display: flex;
	align-items: center;
}

.filename {
	white-space: nowrap;
	overflow: hidden;
	text-overflow: ellipsis;
}

.reset {
	margin-left: auto;
	padding: 0;
	font-size: 30px;
	color: red;
	align-self: end;
}

.mime {
	font-weight: 600;
	margin-right: auto;
}

.right {
	display: flex;
	width: 380px;
	flex-direction: column;
	margin: 20px;
}

.fileDrop {
	flex: 1;
	font-size: 12px;
}

.error {
	margin: 1em 0;
	height: 3em;
	color: #ff1e1e;
}

.actions {
	composes: dialog-actions from global;
	padding: 0;
}
