.buttonPanel {
	composes: controls from global;

	position: absolute;
	left: 0;
	bottom: 0;
	border-top-right-radius: 4px;
}

.iconButton {
	composes: dark from global;

	width: 50px;
	height: 50px;
	font-size: 30px;
}

.imageView {
	height: 100vh;

	/* For ColorPicker */
	overflow: hidden;
}

.metricsPanel {
	position: absolute;
	left: 0;
	bottom: 50px;

	border-top-right-radius: 3px;
	border-bottom-right-radius: 3px;
}

.simple {
	composes: controls from global;
	composes: metricsPanel;

	display: grid;
	grid-template-columns: auto 1fr;
	grid-row-gap: 10px;

	margin: 0;
	padding: 15px;
}
