.spinner {
	display: inline-flex;
	flex-direction: column;

	&:not(:last-child) {
		margin-right: 10px;
	}
}

.numberInput {
	margin-top: 5px;
}

.controls {
	margin-top: 5px;
}

.legend {
	margin-bottom: 8px;
	padding: 4px 0 0 0;
}
