.item:not(:last-child) {
	margin-top: 5px;
	margin-right: 5px;
}

.label {
	display: block;
	margin-bottom: 8px;
}

.body {

}
