.base {
	display: inline-flex;
	align-items: center;

	/* Align center when align-* cannot be used*/
	vertical-align: middle;

	transition: .15s ease;
}

.text {
	composes: base;

	--color: rgba(0, 0, 0, .05);
	--color-light: rgba(0, 0, 0, .08);

	padding: 10px;
	color: inherit;

	&:hover, &:focus-visible {
		background: var(--color);
	}

	&:focus-visible {
		outline: 1px white dotted;
	}

	&:active {
		background: var(--color-light);
	}

	&.active {
		color: #08c5ff;
	}

	/* <a> does not support :enabled */
	&[disabled] {
		color: #999;

		&:hover, &:active {
			background: none;
		}
	}
}

.button {
	composes: base;

	--stripe-width: 32px;
	--color: #0776df;
	--color-light: #0781f2;
	--color-active: #066fd1;
	--color-outline: rgba(0, 112, 217, 0.5);

	padding: 8px 16px;
	border-radius: 4px;

	color: white;
	background-color: var(--color);
	letter-spacing: .5px;
	text-decoration: none;

	&:hover {
		background-color: var(--color-light);
	}

	&:focus-visible {
		outline: none;
		box-shadow: 0 0 0 5px var(--color-outline);
	}

	&:active {
		background-color: var(--color-active);
	}

	&:disabled {
		cursor: default;
		color: #555;
		background-color: #ddd;
	}
}

.outline {
	composes: button;

	background-color: transparent;
	color: var(--color-light);
	border: solid 1px var(--color-light);

	&:hover {
		color: white;
		background-color: var(--color);
		border-color: var(--color);
	}
}

:global(.second) {
	--color: #e10199;
	--color-light: #f024ac;
	--color-active: #d9219b;
	--color-outline: rgba(242, 0, 166, .45);
}

/* Text button in the dark container */
:global(.dark) {
	--color: rgba(255, 255, 255, .2);
	--color-light: rgba(255, 255, 255, .15);
}

.button.active {
	&, &:hover {
		background-color: var(--color);
		background-size: var(--stripe-width);
	}

	background-image: linear-gradient(
			-45deg,
			var(--color-light) 25%,
			transparent 25%,
			transparent 50%,
			var(--color-light) 50%,
			var(--color-light) 75%,
			transparent 75%
	);

	animation: busy linear .4s infinite;
}

@keyframes busy {
	from {
		background-position: 0;
	}
	to {
		background-position: calc(0 - var(--stripe-width));
	}
}
