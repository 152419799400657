@import 'highcharts/css/highcharts.css';

:root {
	/* Chart background, point stroke for markers and columns etc */
	--highcharts-background-color: transparent;

	/* Colors for data series and points. */
	--highcharts-color-0: #4992ff;
	--highcharts-color-1: #7cffb2;
	--highcharts-color-2: #fddd60;
	--highcharts-color-3: #ff6e76;
	--highcharts-color-4: #58d9f9;
	--highcharts-color-5: #05c091;
	--highcharts-color-6: #ff8a45;
	--highcharts-color-7: #8d48e3;
	--highcharts-color-8: #dd79ff;
	--highcharts-color-9: #7798bf;

	/* Neutral colors */
	--highcharts-neutral-color-100: #ffffff;
	--highcharts-neutral-color-80: #e0e0e3;
	--highcharts-neutral-color-60: #e0e0e3;
	--highcharts-neutral-color-40: #666666;
	--highcharts-neutral-color-20: #606063;
	--highcharts-neutral-color-10: #707073;
	--highcharts-neutral-color-5: #505053;
	--highcharts-neutral-color-3: #303033;

	/* Highlight colors https://jsfiddle.net/highcharts/pukradvh/ */
	--highcharts-highlight-color-100: rgb(166, 199, 237);
	--highcharts-highlight-color-80: rgb(134, 165, 201);
	--highcharts-highlight-color-60: rgb(102, 131, 164);
	--highcharts-highlight-color-20: rgb(39, 64, 91);
	--highcharts-highlight-color-10: rgb(23, 47, 73);
}

.highcharts-container {
	font-family: "Unica One", arial, helvetica, sans-serif;
}

.highcharts-title,
.highcharts-subtitle {
	text-transform: uppercase;
}

.highcharts-title {
	font-size: 1.7em;
}

.highcharts-tooltip text {
	fill: #f0f0f0;
}

.highcharts-tooltip-box {
	fill: #000000;
}

.highcharts-range-selector-buttons text {
	fill: silver;
}

.highcharts-yaxis-grid {
	stroke-width: 1px;
}

.highcharts-axis-labels,
.highcharts-axis-title {
	fill: #e0e0e3;
}

.highcharts-navigator .highcharts-navigator-handle {
	fill: var(--highcharts-neutral-color-40);
	stroke: #aaaaaa;
}

.highcharts-navigator .highcharts-navigator-outline {
	stroke: #cccccc;
}

.highcharts-navigator .highcharts-navigator-xaxis .highcharts-grid-line {
	stroke: var(--highcharts-neutral-color-5);
}

.highcharts-button {
	fill: var(--highcharts-neutral-color-5);
}

.highcharts-button text {
	fill: #cccccc;
}

.highcharts-button-hover {
	fill: var(--highcharts-neutral-color-10);
}

.highcharts-button-hover text {
	fill: var(--highcharts-neutral-color-100);
}

.highcharts-button-pressed {
	fill: #000003;
	stroke: var(--highcharts-neutral-color-100);
}

.highcharts-button-pressed text {
	fill: var(--highcharts-neutral-color-100);
}

.highcharts-scrollbar .highcharts-scrollbar-rifles {
	stroke: var(--highcharts-neutral-color-100);
}

.highcharts-scrollbar .highcharts-scrollbar-button {
	stroke: #606063;
	fill: #606063;
}

.highcharts-scrollbar .highcharts-scrollbar-arrow {
	fill: #cccccc;
}

.highcharts-scrollbar .highcharts-scrollbar-thumb {
	fill: #808083;
	stroke: #808083;
}

.highcharts-contextbutton {
	fill: var(--highcharts-neutral-color-5);
}

.highcharts-contextbutton .highcharts-button-symbol {
	stroke: #dddddd;
}

.highcharts-menu {
	background: #222;
	box-shadow: none;
}

.exporting_chart_class {
	--highcharts-background-color:  #222;
}
