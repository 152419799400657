.form {
	composes: dialog-content from global;

	/* Safari does not support flex-gap */
	display: grid;
	grid-template-columns: 1fr;
	grid-gap: 10px;
	align-content: start;
}

.subfields {
	display: flex;
	align-items: center;
	padding-left: 30px;
}

.field {
	display: inline-block;
	margin-right: 20px;
}

.inlineLabel {
	margin-right: 10px;
	margin-left: 30px;
	vertical-align: middle;
}

.label {
	display: block;
	font-size: .875em;
	font-weight: 600;
	color: #444;
	margin-bottom: 5px;
}

.select {
	padding: 5px;
	font-size: 1rem;
}
