.container {
	--color-checked: #069AF9;
	--size: 24px;

	display: inline-block;
	position: relative;
	line-height: 1;
	cursor: pointer;

	&:focus-within {
		outline: dotted 2px;
	}

	&.disabled {
		cursor: default;
	}
}

.input {
	position: absolute;
	opacity: 0;
	cursor: inherit;

	&:checked + .mark {
		color: var(--color-checked);
	}

	&:disabled + .mark {
		color: #aaa;
	}
}

.mark {
	font-size: var(--size);
	color: #777;
	vertical-align: middle;
}

.label {
	display: inline-block;
	vertical-align: middle;
	padding: 0 5px;
}
