.content {
	composes: dialog-content from global;
	text-align: center;
}

.text {
	display: flex;
	justify-content: space-between;
	margin-bottom: 14px;
}

.error {
	color: red;
	font-weight: 600;
}

.progress {
	width: 640px;
}

.buttons {
	composes: dialog-actions from global;
	justify-content: center;
}
