.container {
	composes: dialog-content from global;
	display: flex;
}

.tablist {
	border-right: solid 1px #ddd;
	padding-right: 20px;
}

.tab {
	display: block;
	width: 100%;
	padding: 8px;
	white-space: nowrap;

	text-align: left;
	border-radius: 10px;
	font-size: 1.25em;
	transition-property: background-color;

	&:hover, &:focus-visible {
		outline: none; /* for Chrome */
		background-color: #e8f5fe;
	}
}

.active {
	color: #139df2;
}

.name {
	margin-left: 5px;
	vertical-align: middle;
}

.form {
	flex: 1;
	padding: 0 20px 0 15px;
	overflow-y: scroll;
}
